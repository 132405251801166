import React, { Dispatch, SetStateAction, memo, useCallback } from 'react';
import { Route, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Location } from 'history';
import { ApiResponse } from '@common/services/api/types';
import { EmployeeSurveyDetail } from '@modules/survey/types/objects';
import PageLeaveCheckPopup from '@common/components/confirm-button/page-leave-check-popup';
import { FormSubmissionValues } from '@modules/forms/types';
import NextButton from './next-button';
import SurveySubmissionStep from './survey-submission-step';

type SurveySubmissionStepsProps = {
  className?: string;
  networkId: string;
  survey: ApiResponse<EmployeeSurveyDetail>;
  values: FormSubmissionValues[];
  setValues: Dispatch<SetStateAction<FormSubmissionValues[] | null>>;
  currentPage: number;
  orgId: string;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
};

const SurveySubmissionSteps = memo(({
  networkId,
  survey,
  values,
  setValues,
  currentPage,
  orgId,
  submitting,
  setSubmitting,
  className
}: SurveySubmissionStepsProps) => {

  const surveyId = survey.data.id;

  const { url } = useRouteMatch();
  const shouldRunOnRoute = useCallback((newLocation: Location<unknown>) => {
    const baseUrl = url.substring(0, url.lastIndexOf('/'));
    // if the navigation is about going back and forth in the survey steps
    // let's ask if the user is sure about leaving the survey submission editor
    return !newLocation.pathname.startsWith(baseUrl);
  }, [url]);

  const { t } = useTranslation();

  return (
    <div className={`FormSubmissionEditorContent ${className || ''}`}>
      <style>{'body { background-color: inherit; }'}</style>
      <PageLeaveCheckPopup
        message={t('survey:submission_editor_leave_confirm')}
        shouldRunOnRoute={shouldRunOnRoute}
      />
      {
        survey.data.screens.map((screen, index) => {
          const path = `/networks/${networkId}/survey/${surveyId}/${index + 1}`;
          return (
            <Route key={screen.id} path={path}>
              <SurveySubmissionStep
                screen={screen}
                value={values[index]}
                setValues={(getComponentValues) => {
                  // this check is here just to satisfy the compiler
                  if (typeof getComponentValues !== 'function') return;

                  const newValues = getComponentValues(values[index]);
                  setValues((prevValues) => {
                    if (!prevValues) return null;
                    const valuesClone = [...prevValues];
                    valuesClone[index] = newValues;
                    return valuesClone;
                  });
                }}
              />
            </Route>
          );
        })
      }
      <NextButton
        survey={survey.data}
        networkId={networkId}
        currentPage={currentPage}
        values={values}
        orgId={orgId}
        submitting={submitting}
        setSubmitting={setSubmitting}
      />
    </div>
  );
});

export default SurveySubmissionSteps;
