import React, { Dispatch, SetStateAction, memo } from 'react';
import { ScreenComponentItem } from '@modules/learning/types/objects';
import { getComponent } from './utils';
import ScreenSection from './screen-section';
import { APIScreen, FormSubmissionValues, SetFormSubmissionValues } from '@modules/forms/types';
import { APISurveyScreen } from '@modules/survey/types/objects';
import { EComponentTypes } from '@modules/learning/definitions';

type ScreenComponentsProps = {
  setValues: SetFormSubmissionValues;
  values: FormSubmissionValues;
  setTimezone?: Dispatch<SetStateAction<string | null>>;
  timezone?: string;
  readOnly: boolean;
  screen: APISurveyScreen | APIScreen;
};

const ScreenComponents = memo(({
  screen,
  values,
  timezone,
  setTimezone,
  setValues,
  readOnly
}: ScreenComponentsProps) => {
  return (
    <ScreenSection key={screen.id}>
      {
        screen.components.map((component: ScreenComponentItem) => {
          const Component = getComponent(component.type);
          if (Component) {
            const value = values[component.id];

            let additionalProps = {};
            if (component.type === EComponentTypes.QUESTION_DATE_TIME) {
              additionalProps = { timezone, setTimezone };
            }

            return (
              <div className="ScreenComponent" key={component.id}>
                <Component
                  // @ts-expect-error
                  item={component}
                  // @ts-expect-error
                  value={value}
                  // @ts-expect-error
                  setValues={setValues}
                  // @ts-expect-error
                  readOnly={readOnly}
                  {...additionalProps}
                />
              </div>
            );
          }
          return null;
        })
      }
    </ScreenSection>
  );
});

export default ScreenComponents;
