import {
  FormSubmissionValues, SetFormSubmissionValues
} from '@modules/forms/types';
import {
  DropdownScreenComponent, DropdownScreenComponentOwnProps,
  DropdownScreenComponentValue
} from '@modules/learning/components/screen-preview/component/types/dropdown';
import React, { memo, useCallback } from 'react';
import { Option } from 'react-select';

type DropdownProps = {
  item: DropdownScreenComponentOwnProps['item'];
  setValues: SetFormSubmissionValues;
  value: DropdownScreenComponentValue;
  readOnly?: boolean;
};

type ChangedValue = Option<string>[] | Option<string>;

const Dropdown = memo(({
  item, value, setValues, readOnly = false
}: DropdownProps) => {

  const onChange = useCallback((changedValue: ChangedValue) => {
    setValues((values: FormSubmissionValues) => {
      return {
        ...values,
        [item.id]: Array.isArray(changedValue) ?
          changedValue.map((option) => option.value) as string[] :
          [changedValue.value] as string[]
      };
    });
  }, [setValues, item]);

  return (
    <DropdownScreenComponent
      item={item}
      onChange={readOnly ? undefined : onChange}
      value={value}
    />
  );
});

export default Dropdown;
