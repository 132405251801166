import * as React from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import NetworkComponentPermission from '@common/components/permission/network-component-permission';
import { Popup } from 'semantic-ui-react';
import NetworkForms
  from '@modules/forms/components/network-forms/network-forms';
import * as AnalyticsService from '../../common/services/analytics';
import Route, { PermissionRoute, loadable }
  from '../../common/components/route';
import Permission from '../../common/components/permission';
import { TopBarContentContext } from './top-bar-content-context';
import Icon from '../../common/components/icon';
import ErrorComponent from '../../common/components/error';
import RouteNotFound from '../../common/components/route-not-found';
import TopBar from '../../common/components/top-bar';
import NavigationBar from '../../common/components/navigation-bar';
import Notifications from '../core/components/notifications';
import ProfileContainer from '../core/containers/profile';
import { UserProfileTypes } from '../core/definitions';
import * as organisationSelector from '../organisation/selectors/organisation';
import * as networkSelector from './selectors/network';
import * as userSelector from '../core/selectors/logged-user';
import {
  ESourceTypes, EComponentTypes, EPermissions, ENotificationActivityTypes
} from '../../common/definitions';
import SurveySubmissionEditor from './containers/surveys/survey-submission-editor';

const { EEventNames } = AnalyticsService;

require('./styles.scss');

const ChatContainer = loadable(() => import('../chat/containers/conversations'));
const MessageContainer = loadable(() => import('../social/containers/message-detail'));
const SocialContainer = loadable(() => import('../social/containers/social'));
const SchedulesContainer = loadable(() => import('../schedules/containers/schedules'));
const FlexchangeContainer = loadable(() => import('../flexchange/containers/flexchange'));
const AnalyticsContainer = loadable(() => import('../analytics'));
const NetworkUserContainer = loadable(() => import('./containers/user'));
const EmployeesContainer = loadable(() => import('./containers/users'));
const SettingsContainer = loadable(() => import('./containers/settings'));
const EventsRouter = loadable(() => import('../events/network-router'));
const DocumentsRouter = loadable(() => import('../documents/documents-router'));

const isFormsActive = (match, location) => {
  const dirs = location.pathname.split('/');
  return (dirs[1] === 'networks') && (dirs[3] === 'forms');
};

class NetworkTemplate extends React.Component {

  static getDerivedStateFromError(error) {
    return { error };
  }

  constructor() {
    super();

    this.state = {
      error: null
    };

    this.setTopBarContentRef = (ref) => (this.topBarContentRef = ref);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  UNSAFE_componentWillMount() {
    AnalyticsService.register({ networkId: this.props.network.id });
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname && this.state.error) {
      this.state.error = null;
    }
  }

  componentWillUnmount() {
    AnalyticsService.register({ networkId: undefined });
  }

  static props;

  handleNotificationClick(notification) {
    const {
      history, network, openConversation, selectNetwork, location
    } = this.props;
    const { id, source_type: sourceType, source_id: sourceId } = notification;

    const fnWrapper = (fn) => () => {
      AnalyticsService.track(EEventNames.OPENED_NOTIFICATION, {
        activityType: notification.activity_type,
        sourceType,
        sourceId,
      });

      fn();
    };

    switch (sourceType) {
      case ESourceTypes.SURVEY:
        return fnWrapper(() => {
          const { pathname, search } = location;
          const origin = encodeURIComponent(`${pathname}${search}`);
          const url = `/networks/${network.id}/survey/${sourceId}/1?origin=${origin}`;
          return history.push(url);
        });
      case ESourceTypes.EXCHANGE:
        if (!notification.network_id) return;

        return fnWrapper(async () => {
          await selectNetwork(notification.network_id);

          return history.push(`/networks/${notification.network_id}/flexchange/exchanges/${sourceId}`);
        });
      case ESourceTypes.MESSAGE:
        return fnWrapper(() => history.push(`/messages/${sourceId}?aid=${id}`));
      case ESourceTypes.CONVERSATION:
        return fnWrapper(() => {
          openConversation(sourceId);

          return history.push('/conversations');
        });
      case ESourceTypes.EVENT:
        return fnWrapper(() => history.push(`/networks/${network.id}/events/${notification.source_id}`));
      case ESourceTypes.FORM_SUBMISSION:
        const isCommentCreated = (
          notification.activity_type ===
          ENotificationActivityTypes.FORM_SUBMISSION_COMMENT_CREATED
        );
        if (
          notification.activity_type !== ENotificationActivityTypes.FORM_SUBMISSION_CREATED &&
          !isCommentCreated
        ) {
          return;
        }
        const formId = notification?.meta?.form?.id;
        const subId = notification?.source_id;
        if (!formId || !subId) return;
        let path = `/networks/${network.id}/forms/${formId}/submissions/${subId}`;
        if (isCommentCreated) {
          path += '?showComments=1';
        }
        return fnWrapper(() => history.push(path));
      default:
    }
  }

  handleLogout() {
    const { logout, history } = this.props;

    logout();
    history.push('/auth/login');
  }

  render() {
    const { error } = this.state;
    const {
      history, loggedUser, organisation, networks, network, unread, t
    } = this.props;

    const networkUrl = (path) => `/networks/${network.id}${path}`;

    return (
      <div id="NetworkTemplate">
        <NavigationBar organisation={organisation} onHome={() => history.push(networkUrl('/feed'))}>
          <Permission component={EComponentTypes.SOCIAL}>
            <NavigationBar.Item
              id="feed"
              path={networkUrl('/feed')}
              isActive={(match, l) => (
                l.pathname.includes('/networks/') &&
                (l.pathname.includes('/feed') ||
                l.pathname.includes('/messages'))
              )}
              icon="home"
              tooltip={t('core:tab_timeline')}
              unread={unread.social.total}
            />
          </Permission>
          <NavigationBar.Item
            id="colleagues" path={networkUrl('/users')} icon="group"
            tooltip={t('core:tab_coworkers')}
          />
          <Permission component={EComponentTypes.SCHEDULE}>
            <NavigationBar.Item
              id="schedules" path={networkUrl('/schedules')}
              icon="calendar_today" tooltip={t('core:tab_schedules')}
            />
          </Permission>
          <Permission component={EComponentTypes.FORMS}>
            <NavigationBar.Item
              id="forms" path={networkUrl('/forms/me/forms')} icon="assignment"
              tooltip={t('core:tab_forms')}
              isActive={isFormsActive}
            />
          </Permission>
          <Permission component={EComponentTypes.EVENTS}>
            <NavigationBar.Item
              id="events" path={networkUrl('/events')}
              icon="event" tooltip={t('core:tab_events')}
            />
          </Permission>
          <Permission component={EComponentTypes.DOCUMENTS}>
            <NavigationBar.Item
              id="documents" path={networkUrl('/documents')}
              icon="folder" tooltip={t('core:tab_documents')}
            />
          </Permission>
          <NetworkComponentPermission
            component={EComponentTypes.FLEXCHANGE}
            networks={[network]}
          >
            <NavigationBar.Item
              id="flexchange" path={networkUrl('/flexchange')} icon="sync_alt"
              tooltip={t('core:tab_flexchange')}
            />
          </NetworkComponentPermission>
          <Permission name={[
            EPermissions.NETWORK_USER_STATISTICS_VIEW,
            EPermissions.NETWORK_COMMUNICATION_STATISTICS_VIEW,
            EPermissions.NETWORK_EXCHANGE_STATISTICS_VIEW,
            EPermissions.NETWORK_ONBOARDING_STATISTICS
          ]}
          >
            <NavigationBar.Item
              id="analytics" path={networkUrl('/analytics')} icon="analytics"
              tooltip={t('core:tab_statistics')}
            />
          </Permission>
          <NavigationBar.Divider />
          <Permission name={[
            EPermissions.NETWORK_NETWORKS_UPDATE,
            EPermissions.NETWORK_TEAMS_VIEW,
            EPermissions.NETWORK_USERS_UPDATE,
            EPermissions.NETWORK_NETWORK_ADMINS_VIEW,
            EPermissions.NETWORK_NETWORKS_UPDATE_COMPONENTS
          ]}
          >
            <NavigationBar.Item
              id="settings" path={networkUrl('/settings')}
              isActive={(match, l) => l.pathname.indexOf('/settings') > 0}
              icon="settings" tooltip={t('core:tab_settings')}
            />
          </Permission>
        </NavigationBar>

        <div id="Content">
          <TopBar
            loggedUser={loggedUser}
            organisation={organisation}
            networks={networks}
            network={network}
            setContentRef={this.setTopBarContentRef}
            onOpenProfile={(path) => history.push(`/profile/about${path || ''}`)}
            onLogout={this.handleLogout}
            onSelectNetwork={async (id) => {
              const path = await this.props.selectNetwork(id);

              AnalyticsService.register({ networkId: id });

              return path;
            }}
          >
            <NavLink
              to="/conversations" id="topbar_chat"
              className="TopBar__Trigger TopBar__Trigger--icon"
              activeClassName="TopBar__Trigger--open"
            >
              <Icon type="chat__filled" />
              {unread.chat.total ? <span className="TopBar__Trigger__Unread">{unread.chat.total}</span> : null}
            </NavLink>

            <Popup
              position="bottom right"
              on="click"
              style={{ width: 350, padding: 0 }}
              offset={[0, 4]}
              onOpen={() => AnalyticsService.track(EEventNames.VIEWED_NOTIFICATIONS_LIST)}
              onClose={this.props.clearNotifications}
              content={(
                <Notifications onNotificationClick={this.handleNotificationClick} />
              )}
              trigger={(
                <div className="TopBar__Trigger TopBar__Trigger--icon" id="topbar_notifications">
                  <Icon type="notifications__filled" />
                  {
                    unread.notifications.total ?
                      (
                        <span className="TopBar__Trigger__Unread">{unread.notifications.total}</span>
                      ) :
                      null
                  }
                </div>
              )}
            />
          </TopBar>
          <div className="Content__Wrapper">
            {error && (
              <ErrorComponent error={error} />
            )}
            {!error && (
              <TopBarContentContext.Provider value={this.topBarContentRef}>
                <Switch>
                  <Route
                    path={[
                      '/networks/:networkId/messages/:type/:id',
                      '/networks/:networkId/messages/:type',
                      '/networks/:networkId/feed'
                    ]}
                    component={SocialContainer}
                  />
                  <PermissionRoute
                    path="/networks/:networkId/survey/:surveyId/:page"
                    component={SurveySubmissionEditor}
                    exact
                    permission={{ component: EComponentTypes.SURVEYS }}
                  />
                  <Route
                    path={[
                      '/networks/:networkId/users',
                      '/networks/:networkId/users/filter/:filter'
                    ]}
                    exact
                    component={EmployeesContainer}
                  />
                  <PermissionRoute
                    path="/networks/:networkId/forms"
                    component={NetworkForms}
                    permission={{ component: EComponentTypes.FORMS }}
                  />
                  <Route
                    path={[
                      '/networks/:networkId/users/:userId/edit',
                      '/networks/:networkId/users/:userId'
                    ]}
                    component={NetworkUserContainer}
                  />
                  <Route path="/networks/:networkId/schedules" component={SchedulesContainer} />
                  <PermissionRoute
                    path="/networks/:networkId/flexchange"
                    component={FlexchangeContainer}
                    permission={{ component: EComponentTypes.FLEXCHANGE }}
                  />
                  <Route path="/networks/:networkId/schedules" component={SchedulesContainer} />
                  <PermissionRoute
                    path="/networks/:networkId/events"
                    component={EventsRouter}
                    permission={{ component: EComponentTypes.EVENTS }}
                  />
                  <PermissionRoute
                    path="/networks/:networkId/documents"
                    component={DocumentsRouter}
                    permission={{ component: EComponentTypes.DOCUMENTS }}
                  />
                  <PermissionRoute
                    path="/networks/:networkId/settings"
                    component={SettingsContainer}
                    permission={{
                      name: [
                        EPermissions.NETWORK_NETWORKS_UPDATE,
                        EPermissions.NETWORK_TEAMS_VIEW,
                        EPermissions.NETWORK_USERS_UPDATE,
                        EPermissions.NETWORK_NETWORK_ADMINS_VIEW,
                        EPermissions.NETWORK_NETWORKS_UPDATE_COMPONENTS
                      ]
                    }}
                  />
                  <PermissionRoute
                    path="/networks/:networkId/analytics"
                    component={AnalyticsContainer}
                    permission={{
                      name: [
                        EPermissions.NETWORK_USER_STATISTICS_VIEW,
                        EPermissions.NETWORK_COMMUNICATION_STATISTICS_VIEW,
                        EPermissions.NETWORK_EXCHANGE_STATISTICS_VIEW,
                        EPermissions.NETWORK_ONBOARDING_STATISTICS
                      ]
                    }}
                  />
                  <Redirect exact from="/networks/:networkId" to="/networks/:networkId/feed" />
                  <Route path="/profile">
                    <ProfileContainer
                      userId={loggedUser.id}
                      type={UserProfileTypes.OWN}
                      fetchDetails
                    />
                  </Route>
                  {/* <Route path="/profile" component={ProfileGeneralContainer} /> */}
                  <Route path={['/conversations/:filter', '/conversations']} component={ChatContainer} />
                  <Route path="/messages/:messageId" component={MessageContainer} />
                  <Redirect exact from="/" to={networkUrl('/feed')} />
                  <Route component={RouteNotFound} />
                </Switch>
              </TopBarContentContext.Provider>
            )}
          </div>
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  organisation: organisationSelector.selected(state),
  networks: userSelector.networks(state),
  loggedUser: userSelector.selected(state),
  network: networkSelector.selected(state),
  unread: state.notifications,
  permissions: state.permissions
});

const mapDispatchToProps = {
  logout: require('../authentication/actions/logout').default,
  selectNetwork: require('./actions/select-network').default,
  clearNotifications: require('../core/actions/clear-notifications').default,
  openConversation: require('../chat/actions/open-conversation').default,
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NetworkTemplate));
