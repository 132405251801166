import React, { memo, useCallback, Dispatch, SetStateAction } from 'react';
import { ReceivedQuestionComponentDateTime }
  from '@modules/learning/types/objects';
import {
  DateTimeScreenComponent, DateTimeScreenValue
} from '@modules/learning/components/screen-preview/component/types/date-time';
import { FormSubmissionValues, SetFormSubmissionValues }
  from '@modules/forms/types';
import { Moment } from 'moment';
import { Option } from 'react-select';

type DateTimeProps = {
  item: ReceivedQuestionComponentDateTime;
  value: DateTimeScreenValue;
  setValues: SetFormSubmissionValues;
  setTimezone: Dispatch<SetStateAction<string | null>>;
  timezone: string;
  readOnly?: boolean;
};

const DateTime = memo(({
  value, setValues, item, setTimezone, timezone, readOnly = false
}: DateTimeProps) => {

  const onDateChange = useCallback((date: Moment | null) => {
    return setValues((values: FormSubmissionValues) => {
      return {
        ...values,
        [item.id]: {
          ...(values[item.id] as DateTimeScreenValue),
          date: date?.toDate() || null
        }
      };
    });
  }, [setValues, item]);

  const onTimeChange = useCallback((time?: string) => {
    return setValues((values: FormSubmissionValues) => {
      return {
        ...values,
        [item.id]: {
          ...(values[item.id] as DateTimeScreenValue),
          time: time || null
        }
      };
    });
  }, [setValues, item]);

  const onTimeZoneChange = useCallback((
    option: Option<{ label: string; value: string; }> | null
  ) => {
    if (!option || typeof option.value !== 'string') return;
    setTimezone(option.value);
  }, [setTimezone]);

  // console.log('debug DateTime', DateTime);

  return (
    <DateTimeScreenComponent
      item={item}
      value={value}
      timezone={timezone}
      displayTimeInput
      displayChangeAction={false}
      // @ts-expect-error
      onTimezoneChange={readOnly ? undefined : onTimeZoneChange}
      onDateChange={readOnly ? undefined : onDateChange}
      onTimeChange={readOnly ? undefined : onTimeChange}
    />
  );
});

export default DateTime;
