import { FormSubmissionValues, SetFormSubmissionValues }
  from '@modules/forms/types';
import { ChecklistScreenComponent, ChecklistScreenComponentValue }
  from '@modules/learning/components/screen-preview/component/types/checklist';
import { ReceivedQuestionComponentChecklist }
  from '@modules/learning/types/objects';
import React, { memo, useCallback } from 'react';

type ChecklistProps = {
  item: ReceivedQuestionComponentChecklist;
  setValues: SetFormSubmissionValues;
  value: ChecklistScreenComponentValue;
  readOnly?: boolean;
};

const Checklist = memo(({
  item, value, setValues, readOnly = false
}: ChecklistProps) => {
  const onToggle = useCallback((optionId: string) => {
    setValues((values: FormSubmissionValues) => {
      const oldValue = values[item.id] as ChecklistScreenComponentValue;
      const valueWithoutOption = oldValue.filter((id: string) => {
        return id !== optionId;
      });
      return {
        ...values,
        [item.id]: oldValue.length === valueWithoutOption.length ?
          [...oldValue, optionId] :
          valueWithoutOption
      };
    });
  }, [setValues, item]);
  return (
    <ChecklistScreenComponent
      item={item}
      onToggle={readOnly ? undefined : onToggle}
      value={value}
    />
  );
});

export default Checklist;
