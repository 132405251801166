import { ObjectValues } from '@common/types/util-types';
import Video from './submission-editor-screens/video';
import ExternalVideo from './submission-editor-screens/external-video';
import Text from './submission-editor-screens/text';
import Image from './submission-editor-screens/image';
import Slider from './submission-editor-screens/slider';
import Number from './submission-editor-screens/number';
import DateTime from './submission-editor-screens/datetime';
import Checklist from './submission-editor-screens/checklist';
import QuestionText from './submission-editor-screens/question-text';
import Dropdown from './submission-editor-screens/dropdown';
import Boolean from './submission-editor-screens/boolean';
import FileUpload from './submission-editor-screens/file-upload';
import { EComponentTypes } from '@modules/learning/definitions';
import FileComponent
  from '@modules/learning/components/screen-preview/component/types/file';
import { ReceivedQuestionComponentDateTime, ScreenComponentItem }
  from '@modules/learning/types/objects';
import moment from 'moment';
import {
  APIForm, APIFormSubmission, APIScreen, EFormSubmissionStatuses,
  FormSubmissionFieldValue, FormSubmissionStatus, FormSubmissionValues
} from '@modules/forms/types';
import { DateTimeScreenValue }
  from '@modules/learning/components/screen-preview/component/types/date-time';
import { FileUploadScreenComponentValue }
  from '@modules/learning/components/screen-preview/component/types/file-upload';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { AlertService } from '@common/services/alert';
import { useTranslation } from 'react-i18next';
import Api from '@common/services/api';
import { useHistory } from 'react-router';
import { upload } from '@common/utils/file';
import { getOffsetFromTimezone } from '@common/hooks/time';
import { Attachment } from '@common/types/objects';
import Question from './submission-editor-screens/question';
import { APISurveyScreen, EmployeeSurveyDetail } from '@modules/survey/types/objects';

export const getEditableComponents = (form: APIForm) => {
  return form.screens.map((s: APIScreen) => s.components)
    .flat()
    .filter((component: ScreenComponentItem) => {
      switch (component.type) {
        case EComponentTypes.QUESTION_TEXT:
        case EComponentTypes.QUESTION_MULTIPLE_CHOICE:
        case EComponentTypes.QUESTION_SLIDER:
        case EComponentTypes.QUESTION_DATE_TIME:
        case EComponentTypes.QUESTION_NUMBER:
        case EComponentTypes.QUESTION_DROPDOWN:
        case EComponentTypes.QUESTION_BOOLEAN:
        case EComponentTypes.QUESTION_FILE_UPLOAD:
        case EComponentTypes.QUESTION_CHECKLIST:
          return true;
        default:
          return false;
      }
    });
};

const formatValueToSend = (
  value: FormSubmissionFieldValue,
  item: ScreenComponentItem,
) => {
  switch (item.type) {
    case EComponentTypes.QUESTION_DATE_TIME:
      const { date, time } = value as DateTimeScreenValue;
      if (item.parameters.allow_date_select) {
        if (!date) return null;

        const day = moment(date).format('YYYY-MM-DD');

        if (item.parameters.allow_time_select) {
          if (!time || time.length < '00:00'.length) {
            return ''; // this should produce a validation error from the API
          }
          return `${day} ${time}:00`;
        }

        return day;
      }
      if (item.parameters.allow_time_select) {
        if (!time || time.length < '00:00'.length) {
          return null;
        }
        return `${time}:00`;
      }
      return null;
    case EComponentTypes.QUESTION_FILE_UPLOAD:
      return (value as FileUploadScreenComponentValue).map((file) => {
        return (file as Attachment).id;
      });
    case EComponentTypes.QUESTION_NUMBER:
      if (!value || (value as string)?.length <= 0) {
        return null;
      }
      return (value as string).replace(',', '.');
    default:
      return value;
  }
};

const shouldSkipAnswer = (
  value: ReturnType<typeof formatValueToSend>
) => {
  return (
    value === '' || value === null ||
    (Array.isArray(value) && value.length <= 0) ||
    // NaN check, when removing values from the number input the value is NaN
    value !== value
  );
};

export const formatAnswersToSend = (
  values: FormSubmissionValues,
  form: APIForm | EmployeeSurveyDetail
) => {
  const answers: any = [];
  Object.entries(values).forEach(([componentId, value]) => {
    const componentScreen = form.screens.find((screen: APIScreen | APISurveyScreen) => {
      return !!screen.components.find((component) => {
        return component.id === componentId;
      });
    });

    if (!componentScreen?.question?.id) return;

    const formattedValue = formatValueToSend(value, componentScreen.components[0]);

    if (shouldSkipAnswer(formattedValue)) return;

    const answer = {
      question_id: componentScreen.question.id,
      value: formattedValue
    };

    answers.push(answer);
  });
  return answers;
};

export const getInitialValue = (component: ScreenComponentItem) => {
  switch (component.type) {
    case EComponentTypes.QUESTION_NUMBER:
    case EComponentTypes.QUESTION_TEXT:
      return '';
    case EComponentTypes.QUESTION_DATE_TIME:
      return { time: null, date: null };
    case EComponentTypes.QUESTION_CHECKLIST:
    case EComponentTypes.QUESTION_FILE_UPLOAD:
    case EComponentTypes.QUESTION_DROPDOWN:
    case EComponentTypes.QUESTION_MULTIPLE_CHOICE:
      return [];
    default:
      return null;
  }
};

export const getComponent = (type: ObjectValues<typeof EComponentTypes>) => {
  switch (type) {
    case EComponentTypes.TEXT:
      return Text;
    case EComponentTypes.IMAGE:
      return Image;
    case EComponentTypes.EXTERNAL_VIDEO:
      return ExternalVideo;
    case EComponentTypes.VIDEO:
      return Video;
    case EComponentTypes.ELEARNING_QUESTION:
    case EComponentTypes.QUESTION_MULTIPLE_CHOICE:
      return Question;
    case EComponentTypes.PDF:
      return FileComponent;
    case EComponentTypes.QUESTION_TEXT:
      return QuestionText;
    case EComponentTypes.QUESTION_SLIDER:
      return Slider;
    case EComponentTypes.QUESTION_DATE_TIME:
      return DateTime;
    case EComponentTypes.QUESTION_NUMBER:
      return Number;
    case EComponentTypes.QUESTION_FILE_UPLOAD:
      return FileUpload;
    case EComponentTypes.QUESTION_DROPDOWN:
      return Dropdown;
    case EComponentTypes.QUESTION_BOOLEAN:
      return Boolean;
    case EComponentTypes.QUESTION_CHECKLIST:
      return Checklist;
    default:
      return null;
  }
};

const formatTime = (value: string) => {
  const [hours, minutes] = value.split(':');
  return `${hours}:${minutes}`;
};

const formatReceivedQuestionDateTime = (
  value: string,
  component: ReceivedQuestionComponentDateTime
) => {
  if (!value) {
    return {
      date: null,
      time: null
    };
  }
  if (component.parameters.allow_date_select) {
    if (component.parameters.allow_time_select) {
      const [date, time] = (value as string).split(' ');
      return {
        date: date ? new Date(date) : null,
        time: date ? formatTime(time) : null
      };
    }
    return {
      date: typeof value === 'string' ? new Date(value) : null,
      time: null
    };
  }
  if (component.parameters.allow_time_select) {
    return {
      date: null,
      time: (
        typeof value === 'string' &&
        value.length === '00:00:00'.length ? value : null
      )
    };
  }
  return { date: null, time: '00:00' };
};

const formatReceivedQuestionFileUpload = (
  value: string[],
  submission: APIFormSubmission
) => {
  return (value || []).map((attachmentId) => {
    const attachment = submission.attachments.find((att) => {
      return att.id === attachmentId;
    });
    if (!attachment) return null;

    return {
      ...attachment,
      name: attachment.file_name,
    };
  }).filter((slotItem) => !!slotItem);
};

export const formatReceivedValues = (submission: APIFormSubmission, locale: string) => {
  const values = submission.answers.map(({ question_id, value }) => {
    const questionScreen = submission.form.screens.find((screen) => {
      return screen.question?.id === question_id;
    });
    const component = questionScreen?.components?.[0];

    switch (component?.type) {
      case EComponentTypes.QUESTION_DATE_TIME:
        return formatReceivedQuestionDateTime(
          value as string,
          component
        );
      case EComponentTypes.QUESTION_FILE_UPLOAD:
        return formatReceivedQuestionFileUpload(value as string[], submission);
      case EComponentTypes.QUESTION_NUMBER:
        return value ? Intl?.NumberFormat?.(locale, {
          useGrouping: false,
          maximumFractionDigits: 20,
        }).format(parseFloat(value as string)) : value;
      default:
        return value;
    }
  }).map((value, index) => {
    const key = submission.answers[index].question_id;
    return { [key]: value };
  });
  return Object.assign({}, ...values);
};

export const uploadFile = async (
  orgId: string,
  file: File,
): Promise<Attachment> => {
  const payload = new FormData();
  payload.append('file', file);
  const path = `/v2/organisations/${orgId}/files`;
  const { promise } = await upload(path, payload);
  const info = await promise;
  return {
    ...info,
    preview: info.path,
  };
};

export const useSubmissionSubmit = (
  values: FormSubmissionValues | null,
  form: APIForm | undefined,
  setSubmitting: Dispatch<SetStateAction<boolean>>,
  orgId: string,
  networkId: string,
  submissionId: string | undefined,
  originPageHref: string,
  timezone: string | null
) => {

  const { t } = useTranslation();
  const history = useHistory();

  return useCallback(async (submissionStatus: FormSubmissionStatus) => {
    if (!values || !form || !timezone) return;

    setSubmitting(true);

    try {
      const payload = {
        submission_status: submissionStatus,
        timezone: {
          name: timezone,
          offset_to_gmt_in_seconds: getOffsetFromTimezone(timezone) || 0
        },
        answers: formatAnswersToSend(values, form)
      };
      const baseUrl = `/v1/organisations/${orgId}/users/me/forms`;
      if (submissionId && submissionStatus === EFormSubmissionStatuses.DRAFT) {
        await Api.put(`${baseUrl}/submissions/${submissionId}`, payload);
      } else {
        await Api.post(`${baseUrl}/${form.id}/submissions`, payload);
      }

      const trans = submissionStatus === EFormSubmissionStatuses.PUBLISHED ?
        'forms:response_submitted_feedback' :
        'forms:response_saved_as_draft_feedback';
      AlertService.success(t(trans));

      history.push(originPageHref);
    } catch (error: any) {
      const label = error?.type === 'validation_error' ?
        'common:validation_error' : 'forms:response_failure';
      AlertService.error(t(label));
      setSubmitting(false);
      throw error;
    }
  }, [
    values, orgId, form, setSubmitting, t, networkId, history, timezone,
    submissionId, originPageHref
  ]);
};
